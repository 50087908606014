@import "../../Components/Variables/Variable.scss";

.characterPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 20px;

  .sameLocation,
  .sameStatus {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;

    p {
      border-bottom: 1px solid $primaryColor;
      font-size: $mFont;

      span {
        color: black;
      }
    }
  }
}

.detailCard {
  display: flex;
  border: 1px solid $primaryColor;
  border-radius: 10px;

  .text {
    font-size: $mFont;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: space-between;

    p {
      padding: 0;
      margin: 0;
    }

    .status {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .alive,
    .dead,
    .unknown {
      width: 10px;
      height: 10px;
      border-radius: 100%;
    }

    .alive {
      background-color: green;
    }

    .dead {
      background-color: red;
    }

    .unknown {
      background-color: gray;
    }
  }

  img {
    width: 200px;
    height: 200px;
    border-radius: 10px 0 0 10px;
  }

  p span {
    color: $textColor;
  }

  p {
    color: black;
  }
}

@media (max-width: $breakpointPhone) {
  .characterPage {
    flex-direction: column;
    gap: 10px;
  }

  .detailCard {
    flex-direction: column;
    gap: 10px;

    img {
      width: 100%;
      height: 250px;
      border-radius: 10px 10px 0 0;
      object-fit: cover;
    }
  }
}
