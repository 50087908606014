@import "../Variables/Variable.scss";

.card {
  position: relative;
  display: flex;
  gap: 5px;
  border: 1px solid $textColor;
  border-radius: 10px;
  cursor: default;
  transition-duration: 1s;

  .cardContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: space-between;
  }

  img {
    width: 150px;
    height: 150px;
    border-radius: 10px 0 0 10px;
    border-right: 1px solid $textColor;
  }

  a {
    color: $primaryColor;
    text-decoration: none;

    &:hover {
      color: #004059;
      transition-duration: 1s;
    }
  }

  div span {
    color: $textColor;
  }

  div {
    color: black;
    text-transform: capitalize;
  }

  .isFavorite {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (max-width: $breakpointComputer) {
  .card {
    flex-direction: column;
    gap: 10px;

    .cardContainer {
      gap: 10px;
    }

    img {
      width: 100%;
      height: 200px;
      border-radius: 10px 10px 0 0;
      object-fit: cover;
    }

    .isFavorite {
      background-color: white;
      border-radius: 10px;
      padding: 1px;
    }
  }
}
