@import "../Variables/Variable.scss";

.loadingContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: $primaryColor;
  font-size: $mFont;
  gap: 10px;

  .loadingText {
    color: $primaryColor;
  }

  div {
    width: 20px;
    height: 20px;
    border: 2px solid $primaryColor;
    border-radius: 10px;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
