$bgColor: #fffefe;

$textColor: #858f91;
$primaryColor: #009bda;

$navBgColor: #ffffff;

$bFont: 24px;
$mFont: 20px;
$sFont: 16px;

// responsive

$breakpointComputer: 1200px;
$breakpointTablet: 768px;
$breakpointPhone: 480px;
