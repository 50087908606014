@import "../../Components//Variables/Variable.scss";

.locaitonPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  position: relative;
}

input {
  outline: none;
  border: 1px solid $primaryColor;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 0 0 10px 0;
}

.cardDesign {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.statusFilter {
  margin: 0 10px 10px 0;
  border: 1px solid $primaryColor;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  text-transform: capitalize;

  &:disabled {
    background-color: $textColor;
    color: #fff;
    border: 1px solid $textColor;
    cursor: not-allowed;
  }
}

.activeStatusFilter {
  background: $primaryColor;
  color: #ffffff;
}

.locationDetail {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  position: relative;

  p {
    color: black;
    font-size: $bFont;
    padding: 0;
    margin: 0 0 10px 0;
    text-align: center;
  }
}

@media (max-width: $breakpointTablet) {
  .cardDesign {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (max-width: $breakpointPhone) {
  .cardDesign {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
