@import "../Variables/Variable.scss";

.pagination {
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: end;
  background-color: $navBgColor;
  gap: 10px;
  margin: 0;
  padding: 20px 0;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: 1px solid $primaryColor;
    padding: 5px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      color: #f9f9f9;
      background-color: $primaryColor;
      transition-duration: 1s;
    }
  }

  .activePagination {
    background-color: $primaryColor;
    color: #f9f9f9;
  }

  p {
    margin: 0;
  }

  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: 1px solid $primaryColor;
    padding: 5px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      color: $primaryColor;
      transition-duration: 1s;
    }
  }
}
